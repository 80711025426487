@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Open Sans Condensed";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensanscondensed/v23/z7NFdQDnbTkabZAIOl9il_O6KJj73e7Ff1GhPuI.ttf)
    format("truetype");
}

@font-face {
  font-family: "Reaver";
  src: url("https://cdn.cloudflare.steamstatic.com/apps/dota2/fonts/Reaver-Regular.woff")
      format("woff"),
    url("https://cdn.cloudflare.steamstatic.com/apps/dota2/fonts/Reaver-Regular.ttf")
      format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Reaver";
  src: url("https://cdn.cloudflare.steamstatic.com/apps/dota2/fonts/Reaver-Light.woff")
      format("woff"),
    url("https://cdn.cloudflare.steamstatic.com/apps/dota2/fonts/Reaver-Regular-Light.ttf")
      format("truetype");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Reaver";
  src: url("https://cdn.cloudflare.steamstatic.com/apps/dota2/fonts/Reaver-SemiBold.woff")
      format("woff"),
    url("https://cdn.cloudflare.steamstatic.com/apps/dota2/fonts/Reaver-Regular-SemiBold.ttf")
      format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Reaver";
  src: url("https://cdn.cloudflare.steamstatic.com/apps/dota2/fonts/Reaver-Bold.woff")
      format("woff"),
    url("https://cdn.cloudflare.steamstatic.com/apps/dota2/fonts/Reaver-Regular-Bold.ttf")
      format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Reaver";
  src: url("https://cdn.cloudflare.steamstatic.com/apps/dota2/fonts/Reaver-Black.woff")
      format("woff"),
    url("https://cdn.cloudflare.steamstatic.com/apps/dota2/fonts/Reaver-Regular-Black.ttf")
      format("truetype");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Radiance";
  src: url("https://cdn.cloudflare.steamstatic.com/apps/dota2/fonts/radiance.eot?");
  src: url("https://cdn.cloudflare.steamstatic.com/apps/dota2/fonts/radiance.eot?#iefix")
      format("embedded-opentype"),
    url("https://cdn.cloudflare.steamstatic.com/apps/dota2/fonts/radiance.woff")
      format("woff"),
    url("https://cdn.cloudflare.steamstatic.com/apps/dota2/fonts/radiance.ttf")
      format("truetype"),
    url("https://cdn.cloudflare.steamstatic.com/apps/dota2/fonts/radiance.svg#ywftsvg")
      format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Radiance";
  src: url("https://cdn.cloudflare.steamstatic.com/apps/dota2/fonts/radiance-bold.eot?");
  src: url("https://cdn.cloudflare.steamstatic.com/apps/dota2/fonts/radiance-bold.eot?#iefix")
      format("embedded-opentype"),
    url("https://cdn.cloudflare.steamstatic.com/apps/dota2/fonts/radiance-bold.woff")
      format("woff"),
    url("https://cdn.cloudflare.steamstatic.com/apps/dota2/fonts/radiance-bold.ttf")
      format("truetype"),
    url("https://cdn.cloudflare.steamstatic.com/apps/dota2/fonts/radiance-bold.svg#ywftsvg")
      format("svg");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Radiance";
  src: url("https://cdn.cloudflare.steamstatic.com/apps/dota2/fonts/radiance-semibold.eot?");
  src: url("https://cdn.cloudflare.steamstatic.com/apps/dota2/fonts/radiance-semibold.eot?#iefix")
      format("embedded-opentype"),
    url("https://cdn.cloudflare.steamstatic.com/apps/dota2/fonts/radiance-semibold.woff")
      format("woff"),
    url("https://cdn.cloudflare.steamstatic.com/apps/dota2/fonts/radiance-semibold.ttf")
      format("truetype"),
    url("https://cdn.cloudflare.steamstatic.com/apps/dota2/fonts/radiance-semibold.svg#ywftsvg")
      format("svg");
  font-weight: 700;
  font-style: normal;
}

body {
  margin: 0;
  font-family: "Outfitw", serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: rgb(33, 37, 40);
}

::-webkit-scrollbar-thumb {
  background: #fafafa;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.gray {
  background-color: #f5f5f5 !important;
}

.widget-title {
  margin-bottom: 20px;
  padding-bottom: 12px;
  border-bottom: 1px solid #e1e7ec;
  border-color: rgba(255, 255, 255, 0.12);
  color: rgba(255, 255, 255, 0.5);
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
}

.slider {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 60vh;
  width: 100%;
}

.slider--content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60vh;
}

.slider--feature {
  text-align: center;
}

.feature--title {
  color: #fff;
  font-weight: 700;
  text-shadow: 0 0 10px black, 0 0 5px black, 0 0 1px black, 0 0 2px black;
  text-transform: capitalize;
}

.feature--text {
  /* font-size: 1rem; */
  color: #fff;
  margin: 1rem 0;
  margin-bottom: 25px;
  text-shadow: 0 0 10px black, 0 0 5px black, 0 0 1px black, 0 0 2px black;
}

.slider__btn-right,
.slider__btn-left {
  background: transparent;
  border: none;
  outline: none;
  font-size: 4rem;
  color: #eee;
  padding: 0 1rem;
  cursor: pointer;
  transition: transform 0.1s ease-in-out;
}

.feature__btn {
  background: #fff;
  text-transform: capitalize;
  border: none;
  color: #444;
  border: 1px solid #444;
  outline: none;
  font-weight: 700;
  padding: 0.8rem 2rem;
  cursor: pointer;
  border-radius: 20px;
}

.slider__btn-left:hover,
.slider__btn-right:hover {
  transform: scale(0.95);
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.fadeIn {
  animation: fadeIn 0.5s;
}


.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  left: 36px;
  top: 36px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373a47;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: #373a47;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}