@import 'fonts.css';

body {
    font-family: "Maven Pro", Helvetica, Arial, sans-serif !important;
    color: #606975 !important;
    font-size: 14px !important;
    font-weight: normal !important;
    text-transform: none !important;
    line-height: 1.5 !important;

}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.main {
    min-height: 97vh;
}

.card-group:hover {
    box-shadow: 0 40px 60px -20px rgba(12, 5, 62, 0.15);
    border: 1px solid;
    z-index: 100;
}

.card-group:hover .card-title {
    color: #4e22d0;
}

nav {
    border-bottom: 2px solid rgba(125, 125, 125, .6);
}

footer {
    font-style: normal;
    padding-top: 2rem;
    border-top: 2px solid rgba(125, 125, 125, .6);
    background: #374250;
    padding-bottom: 2rem;
    color: rgba(255, 255, 255, 0.5)
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.text-center {
    text-align: center !important;
}

.padding-bottom-1x {
    padding-bottom: 24px !important;
}

.mb-30 {
    margin-bottom: 30px !important;
}

.text-center {
    text-align: center !important;
}

h3, .h3 {
    margin-bottom: 12px;
    font-size: 24px;
    line-height: 1.25;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    margin: 0;
    font-family: inherit;
    font-style: normal;
    font-weight: 500;
    text-transform: none;
}

.header {
    text-align: center;
    border: 1px solid rgb(225, 231, 236);
    background: rgb(245, 245, 245);
}

.ring-container {
    position: relative;
}

.timeline {
    list-style: none;
    padding: 10px 0 10px;
    position: relative;
}

ul {
    list-style: none;
}

.timeline > li {
    margin-bottom: 10px;
    position: relative;
}

.timeline > li .timeline-badge {
    background-color: #1a2531;
    border-radius: 50%;
    color: #fff;
    font-size: 1.6em;
    height: 50px;
    left: 50px;
    line-height: 45px;
    margin-left: -25px;
    position: absolute;
    text-align: center;
    top: 16px;
    width: 50px;
    border: 3px solid #fff;
}

.timeline > li > .timeline-panel {
    border-radius: 2px;
    border: 1px solid #fff;
    padding: 20px;
    position: relative;
    color: #fff;
    background-color: #1a2531;
    text-align: left;
}

.timeline > li .timeline-badge + .timeline-panel .timeline-arrow {
    width: 50px;
    height: 50px;
    position: absolute;
    top: 20px;
    left: 0;
    -webkit-transform: translateX(-102%);
    transform: translateX(-102%);
    overflow: hidden;
    z-index: 0;
}

.timeline-body {
    word-break: break-word;
}

.timeline > li > .timeline-panel .timeline-footer {
    margin-top: 20px;
    margin: 20px -20px -20px -20px;
    width: calc(100% + 40px);
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 10px;
}

ul {
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
}

.circle {
    width: 10px;
    height: 10px;
    background-color: red;
    border-radius: 50%;
    position: absolute;
    top: 7px;
    left: 7px;
}

.ringring {
    border: 3px solid red;
    -webkit-border-radius: 30px;
    height: 25px;
    width: 25px;
    position: absolute;
    -webkit-animation: pulsate 1s ease-out;
    -webkit-animation-iteration-count: infinite;
    opacity: 0;
}

.content {
    padding: 30px;
}

p {
    margin: 0 0 16px;
}

.market-button.windows-button {
    background-image: url(data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTYuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjI0cHgiIGhlaWdodD0iMjRweCIgdmlld0JveD0iMCAwIDQ4MCA0ODAiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDQ4MCA0ODA7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPGc+Cgk8cGF0aCBkPSJNMC4xNzYsMjI0TDAuMDAxLDY3Ljk2M2wxOTItMjYuMDcyVjIyNEgwLjE3NnogTTIyNC4wMDEsMzcuMjQxTDQ3OS45MzcsMHYyMjRIMjI0LjAwMVYzNy4yNDF6IE00NzkuOTk5LDI1NmwtMC4wNjIsMjI0ICAgbC0yNTUuOTM2LTM2LjAwOFYyNTZINDc5Ljk5OXogTTE5Mi4wMDEsNDM5LjkxOEwwLjE1Nyw0MTMuNjIxTDAuMTQ3LDI1NmgxOTEuODU0VjQzOS45MTh6IiBmaWxsPSIjMDBiY2YyIi8+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPC9zdmc+Cg==);
}

.market-button {
    display: inline-block;
    margin-right: 14px;
    margin-bottom: 14px;
    padding: 5px 14px 5px 45px;
    transition: background-color .3s;
    border: 1px solid #e1e7ec;
    border-radius: 5px;
    background-position: center left 12px;
    background-color: #ffffff;
    background-size: 24px 24px;
    background-repeat: no-repeat;
    text-decoration: none;
}

.market-button .mb-subtitle {
    display: block;
    margin-bottom: -4px;
    color: #9da9b9;
    font-size: 12px;
}

.market-button .mb-title {
    display: block;
    color: #606975;
    font-size: 18px;
}

.market-button:hover {
    background-color: #f5f5f5;
}